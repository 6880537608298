<template>
  <container class="d-flex justify-center">
    <v-card :loading="loading" width="600">
      <v-card-title>
        {{ $capitalize($tc("model.roles_roles_title")) }}
        <v-spacer></v-spacer>
        <v-btn
          v-if="$store.getters['auth/getScope']('auth.roles.add')"
          @click="handleNewRole()"
          color="primary"
          >{{ $capitalize($tc("model.roles_button_new_role")) }}</v-btn
        >
      </v-card-title>
      <v-card-text class="mt-5">
        <v-text-field
          v-model="filter"
          :placeholder="$capitalize($tc('model.roles_role_search'))"
          prepend-inner-icon="mdi-magnify"
          outlined
          @keyup.enter="getRoles()"
        ></v-text-field>
      </v-card-text>
      <v-list>
        <v-list-item-group v-for="role in roles" :key="role.id">
          <v-list-item @click="handleEditRole(role)" inactive>
            <v-list-item-content>
              <v-list-item-title>
                {{ role.name }}
                <span class="text--secondary caption">
                  {{
                    role.fallback_language ? ` (${role.fallback_language})` : ""
                  }}
                </span>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $tc("model.roles_assigned_function", role.scopes.length) }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                v-if="$store.getters['auth/getScope']('auth.scopes.read')"
                @click.stop="handleChangeScopes(role)"
                icon
              >
                <v-icon>mdi-format-list-bulleted</v-icon>
              </v-btn>
              <v-btn
                v-if="$store.getters['auth/getScope']('auth.roles.delete')"
                @click.stop="handleDeleteRole(role)"
                icon
                :loading="role.id === delete_id"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
        </v-list-item-group>
      </v-list>
    </v-card>
    <v-dialog v-model="roles_dialog" max-width="500">
      <v-card>
        <v-card-title>
          {{ form.name }}
          <v-spacer></v-spacer>
          <v-btn icon @click="roles_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <div v-for="scope in scopes" :key="scope.path">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ scope.name }}</v-list-item-title>
              <v-list-item-subtitle>{{
                scope.description
              }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch
                :disabled="
                  !$store.getters['auth/getScope']('auth.roles.edit_scopes')
                "
                v-model="scopes_form"
                :value="scope.id"
                inset
              ></v-switch>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="roles_dialog = false" text>{{
            $capitalize($tc("model.roles_button_cancel"))
          }}</v-btn>
          <v-btn
            v-if="$store.getters['auth/getScope']('auth.roles.edit_scopes')"
            @click="handleSubmitScopes"
            :loading="submitting"
            color="green"
            dark
            >{{ $capitalize($tc("model.roles_button_save")) }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title>
          {{ $capitalize($tc("model.roles_role_title_form")) }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <ValidationObserver ref="form" v-slot="{}">
          <v-form @submit.prevent="handleSubmitForm()">
            <v-card-text>
              <ValidationProvider
                name="name"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="form.name"
                  name="name"
                  :label="$capitalize($tc('model.roles_name'))"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="dialog = false" text>{{
                $capitalize($tc("model.roles_button_cancel"))
              }}</v-btn>
              <v-btn type="submit" color="primary" :loading="submitting">{{
                $capitalize($tc("model.roles_button_save"))
              }}</v-btn>
            </v-card-actions>
          </v-form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </container>
</template>

<script>
import { index, destroy, update, store, updateScopes } from "../services/roles";
import { index as indexScopes } from "../services/scopes";

export default {
  name: "roles",
  data: () => ({
    roles: [],
    dialog: false,
    loading: false,
    form: {},
    submitting: false,
    delete_id: null,
    filter: "",
    roles_dialog: false,
    scopes_form: [],
    scopes: []
  }),
  methods: {
    handleChangeScopes(role) {
      this.scopes_form = role.scopes.map(scope => scope.id);
      this.form = { ...role };
      this.roles_dialog = true;
    },
    async handleSubmitScopes() {
      this.submitting = true;
      try {
        await updateScopes({ scopes: this.scopes_form }, this.form.id);
      } catch (e) {
        this.$store.commit("sendMessage", {
          text: e.response.data.message,
          color: "red"
        });
      } finally {
        this.submitting = false;
      }

      this.roles_dialog = false;
      await this.getRoles();
    },
    async getRoles() {
      this.loading = true;
      try {
        this.roles = await index(this.filter);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async handleSubmitForm() {
      const validate = await this.$refs.form.validate();
      if (!validate) return;

      this.submitting = true;
      try {
        if (this.form.id) {
          await update(this.form.id, this.form);
        } else {
          await store(this.form);
        }
        this.dialog = false;
        await this.getRoles();
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: `Falha ao submeter formulário`,
          color: "red"
        });
      } finally {
        this.submitting = false;
      }
    },
    async handleDeleteRole(item) {
      if (confirm("Tem certeza que deseja excluir?")) {
        try {
          this.delete_id = item.id;
          await destroy(item.id);

          this.roles = this.roles.filter(role => role.id !== item.id);
        } catch (error) {
          this.$store.commit("sendMessage", {
            text: `Falha ao excluir item`,
            color: "red"
          });
        } finally {
          this.delete_id = null;
        }
      }
    },
    handleEditRole(role) {
      if (this.$store.getters["auth/getScope"]("auth.roles.edit")) {
        this.form = { ...role };
        this.dialog = true;
      }
    },
    handleNewRole() {
      this.form = {};
      this.dialog = true;
      if (this.$refs.form) this.$refs.form.reset();
    },
    async getScopes() {
      try {
        this.scopes = await indexScopes(this.filter);
      } catch (error) {
        console.error(error);
      }
    }
  },
  created() {
    this.getRoles();
    if (this.$store.getters["auth/getScope"]("auth.scopes.read"))
      this.getScopes();
  }
};
</script>
